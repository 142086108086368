import React from 'react';

export const payload = {
  main: {
    title: 'Why is Angular worth your time?',
    body: (
      <>
        This framework has gained popularity in the past few years thanks to its{' '}
        <strong>officially supported libraries with generic functionalities</strong> allowing accelerated software
        development.
      </>
    ),
    subtitle: 'With Angular, you can build:',
    chips: [
      'server-side rendered (SSR) applications',
      'single-page applications (SPAs)',
      'enterprise web applications',
      'progressive web applications (PWAs)',
    ],
  },
  info: (
    <>
      Thanks to its modular development structure, Angular can easily deal with modern challenges such as, a necessity
      to scale an application fast according to market needs.
      <br />
      <br />
      Moreover, <strong>TypeScript is Angular{"'"}s primary language</strong> for application development. It makes the
      application easier to scale and debug for developers.
      <br />
      <br />
      Angular applications take less time to code, load faster, and require fewer resources. Taken all together, these
      make Angular the perfect choice for any business which wants to stay up-to-date with its product.
    </>
  ),
};
