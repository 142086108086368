import React from 'react';

import featuredImage from './images/featured-image-angular.jpg';
import headerImage from '@images/header-images/angular-development-desktop-header-image.svg';
import headerImageMobile from '@images/header-images/angular-development-mobile-header-image.svg';
import scalabilityIcon from './images/scalability.svg';
import googleSupportIcon from './images/google-support.svg';
import detailedDocumentationIcon from './images/detailed-documentation.svg';
import flexibilityIcon from './images/flexibility.svg';

import { java, go, cPlusPlus, python } from '@images/logos-techstack-bigger';

import { paths } from '@src/constants/pathsEnum';

import * as styles from './styles.module.scss';

export const angularDevelopmentSEOProps = {
  title: 'Angular Development Services - CodiLime Company',
  description:
    'Seeking a reliable partner who can provide you with Angular development services? Look no further – we are here to help!',
  featuredImage: `https://codilime.com${featuredImage}`,
};

const universalTitle = (
  <>
    <span className={styles.blueText}>Angular</span> development services
  </>
);

export const headerLayoutCProps = {
  desktopBackgroundImage: headerImage,
  mobileBackgroundImage: headerImageMobile,
  backgroundImageAlt: 'Angular development services',
  title: universalTitle,
};

export const angularGoodChoiceProps = {
  section: {
    id: 'angular-benefits',
    title: 'Why Angular is a good choice',
    classNames: {
      titleStyles: styles.titleStyles,
      outerWrapper: styles.outerWrapper,
    },
  },
  tiles: [
    {
      icon: scalabilityIcon,
      title: 'Scalability',
      description: (
        <p className={styles.initializeParagrapgh}>
          Angular features a modular development structure and lazy loading. That means that{' '}
          <span className={styles.boldTextMobileHover}>scaling an application becomes much easier</span>, and that a
          module is downloaded only when the user has a need for it.
        </p>
      ),
    },
    {
      icon: googleSupportIcon,
      title: 'Google support',
      description: (
        <p className={styles.initializeParagrapgh}>
          Developed in Google, <span className={styles.boldTextMobileHover}>Angular is strongly supported</span> by the
          company with constant updates and new releases.
        </p>
      ),
    },
    {
      icon: detailedDocumentationIcon,
      title: 'Detailed documentation',
      description: (
        <p className={styles.initializeParagrapgh}>
          Angular comes with extensive and{' '}
          <span className={styles.boldTextMobileHover}>easy-to-read documentation</span>, covering all aspects of the
          framework
        </p>
      ),
    },
    {
      icon: flexibilityIcon,
      title: 'Flexibility',
      description: (
        <p className={styles.initializeParagrapgh}>
          The framework features{' '}
          <span className={styles.boldTextMobileHover}>
            reusable code that can be applied across different projects
          </span>
          . It allows you to save both time and money.
        </p>
      ),
    },
  ],
};

export const blocksRedirectingToResourcesProps = {
  sectionProps: {
    title: 'Our publications about software development',
    subtitle: (
      <>
        Software development is one of our areas of expertise at CodiLime. Check out our articles to learn more about
        our business approach and how we build <b>scalable solutions adjusted to modern challenges</b>.
      </>
    ),
    id: 'our-articles',
  },
  blueSection: true,
  blocks: [
    {
      text: 'The Seven Stages of the SDLC',
      link: '/blog/the-stages-of-the-sdlc/',
    },
    {
      text: 'What is software testing and why is it important for the SDLC',
      link: '/blog/what-is-software-testing-and-why-is-it-important-for-the-sdlc/',
    },
    {
      text: 'SDLC methodologies—which one to choose for your project?',
      link: '/blog/sdlc-methodologies/',
    },
    {
      text: 'How long does it take to make an application?',
      link: '/blog/how-long-does-it-take-to-make-an-app/',
    },
  ],
};

export const blocksChangingBackgroundColorOnHoverProps = {
  title: 'Angular development services - our approach',
  classNames: {
    outerWrapper: styles.blocksChangingBackgroundColorOnHoverOuterWrapper,
  },
};

export const techStackProps = {
  sectionProps: {
    title: 'Technologies we use with Angular',
    subtitle:
      'At CodiLime, while developing projects for our clients, we often use Angular with GraphQL for APIs, but that’s not all. Below, we share our go-to Angular combos: ',
    id: 'angular-teck-stack',
  },
  lessInFirstLine: false,
  defaultColor: '#00aeef',
  data: [
    java,
    python,
    go,
    cPlusPlus,
    {
      link: paths.TECHNOLOGIES,
      textOnly: 'Check more',
    },
  ],
};

export const blocksInRowsWithSomeActiveProps = {
  sectionProps: {
    title: 'Angular’s superpowers',
    subtitle: (
      <>
        Angular has gained the trust of companies like PayPal, UpWork, Forbes, and Samsung, to name just a few.
        <br />
        Check out which Angular features help us support our worldwide partners in creating outstanding and up-to-date
        tech products.
      </>
    ),
    id: 'angular-superpowers',
    classNames: {
      outerWrapper: styles.blocksInRowsWithSomeActiveOuterWrapper,
    },
  },
  sections: [
    {
      title: `Angulars ecosystem`,
      description: (
        <>
          The Angular framework delivers outstanding applications thanks to its <b>active community</b> and unique
          development tools.
        </>
      ),
    },
    {
      title: 'MEAN stack',
      description: (
        <>
          <b>Angular integrates easily</b> with the MongoDB database, the Express.js web application server framework,
          and the Node.js environment
        </>
      ),
    },
    {
      title: 'Fewer bugs',
      description: (
        <>
          Angular’s <b>code is less prone to bugs</b> and has better overall security thanks to its tools and support.
        </>
      ),
    },
    {
      title: 'Reusable code',
      description: (
        <>
          The Angular framework allows you to <b>reuse code for different applications,</b> for example progressive web
          applications, SPAs, ERSs, or mobile.
        </>
      ),
    },
    {
      title: 'A Google tool',
      description: (
        <>
          Since Angular is Google’s framework, it is regularly updated and <b>constantly improved.</b> What’s more, the
          framework’s community is there if you need any help.
        </>
      ),
    },
  ],
};
