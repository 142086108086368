import React from 'react';

import SEO from '@commons/SEO';
import Layout from '@src/layouts';
import {
  DesktopHeaderLayoutC,
  MobileHeaderLayoutC,
  BlocksRedirectingToResources,
  BlocksChangingBackgroundColorOnHover,
  NewClientsTestimonials,
  TechStack,
  NewStandardSection,
  BlocksInRowsWithSomeActive,
} from '@src/components/commons';
import { AngularGoodChoice } from '@pages-impl/angular-development';
import { IsAngularWorthYourTime } from '@pages-impl/angular-development';

import {
  angularDevelopmentSEOProps,
  headerLayoutCProps,
  blocksRedirectingToResourcesProps,
  blocksChangingBackgroundColorOnHoverProps,
  techStackProps,
  blocksInRowsWithSomeActiveProps,
} from '@pages-impl/angular-development/payload';

export default function AngularDevelopment(props) {
  return (
    <Layout
      displayTopBanner={false}
      contactFormType="services"
      contactFormTitle="Let Angular skyrocket your product"
      {...props}
    >
      <SEO {...angularDevelopmentSEOProps} />
      <DesktopHeaderLayoutC {...headerLayoutCProps} />
      <MobileHeaderLayoutC {...headerLayoutCProps} />
      <NewStandardSection>
        <p>
          At CodiLime, we make sure we understand your business needs, vision and goals. Our Angular developers will
          help you take your business to the next level.
        </p>
      </NewStandardSection>
      <IsAngularWorthYourTime />
      <BlocksInRowsWithSomeActive {...blocksInRowsWithSomeActiveProps} />
      <AngularGoodChoice />
      <TechStack {...techStackProps} />
      <NewClientsTestimonials />
      <BlocksChangingBackgroundColorOnHover {...blocksChangingBackgroundColorOnHoverProps} />
      <BlocksRedirectingToResources {...blocksRedirectingToResourcesProps} />
    </Layout>
  );
}
