import React from 'react';
import cx from 'classnames';
import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import * as styles from './is-angular-worth-your-time.module.scss';
import { payload } from './payload';

export const IsAngularWorthYourTime = () => {
  return (
    <NewStandardSection
      id="why-angular"
      classNames={{ innerWrapper: styles.container, outerWrapper: styles.outerWrapper }}
    >
      <div className={styles.tile}>
        <div className={styles.text}>
          <h2 className={styles.space}>{payload.main.title}</h2>
          <div className={styles.space}>{payload.main.body}</div>
          <div>{payload.main.subtitle}</div>
        </div>
        <div className={styles.chips}>
          {payload.main.chips.map((chip) => (
            <div key={chip} className={styles.chip}>
              <span>{chip}</span>
            </div>
          ))}
        </div>
      </div>
      <div className={cx(styles.tile, styles.info)}>
        <div>{payload.info}</div>
      </div>
    </NewStandardSection>
  );
};
