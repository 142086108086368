import React from 'react';

import { NewStandardSection } from '@commons/new-standard-section/NewStandardSection';
import { GrayCard } from '@commons/gray-card/GrayCard';

import { angularGoodChoiceProps } from '@pages-impl/angular-development/payload';

import * as styles from './angular-good-choice.module.scss';

export const AngularGoodChoice = () => {
  const { section, tiles } = angularGoodChoiceProps;
  return (
    <NewStandardSection {...section}>
      <div className={styles.cardsContainer}>
        {tiles.map((tile) => (
          <GrayCard key={tile.title} {...tile} />
        ))}
      </div>
    </NewStandardSection>
  );
};
