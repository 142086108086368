import React from 'react';

import * as styles from './gray-card.module.scss';

export const GrayCard = ({ title, description, icon }) => {
  return (
    <div className={styles.cardContainer}>
      <img loading="lazy" className={styles.iconContainer} src={icon} alt={title} />
      <h3 className={styles.cardTitle}>{title}</h3>
      <p className={styles.paragrapghContainer}>{description}</p>
    </div>
  );
};
